import { FC } from 'react';
import IconProps from './IconProps';

const DistributeIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4.66667" cy="8.50033" r="2.66667" transform="rotate(90 4.66667 8.50033)" stroke="CurrentColor" strokeWidth="1.33333" />
      <path
        d="M12.2222 8.50098C12.2222 9.48282 13.0182 10.2788 14 10.2788C14.9818 10.2788 15.7778 9.48282 15.7778 8.50098C15.7778 7.51914 14.9818 6.7232 14 6.7232C13.0182 6.7232 12.2222 7.51914 12.2222 8.50098ZM8 8.83431L14 8.83431L14 8.16764L8 8.16764L8 8.83431Z"
        fill="CurrentColor"
      />
      <path
        d="M9.33398 2.05621C8.35214 2.05621 7.55621 2.85214 7.55621 3.83398C7.55621 4.81582 8.35214 5.61176 9.33398 5.61176C10.3158 5.61176 11.1118 4.81582 11.1118 3.83398C11.1118 2.85214 10.3158 2.05621 9.33398 2.05621ZM6.53863 6.43044L9.53863 4.0971L9.12934 3.57087L6.12934 5.9042L6.53863 6.43044Z"
        fill="CurrentColor"
      />
      <path
        d="M9.33398 14.9448C8.35214 14.9448 7.55621 14.1488 7.55621 13.167C7.55621 12.1852 8.35214 11.3892 9.33398 11.3892C10.3158 11.3892 11.1118 12.1852 11.1118 13.167C11.1118 14.1488 10.3158 14.9448 9.33398 14.9448ZM6.17215 10.8812L7.83882 11.8812L7.49582 12.4528L5.82915 11.4528L6.17215 10.8812ZM7.83882 11.8812L9.50548 12.8812L9.16249 13.4528L7.49582 12.4528L7.83882 11.8812Z"
        fill="CurrentColor"
      />
    </svg>
  );
};

export default DistributeIcon;
